import React, { useContext } from 'react';
import PlansPage from 'src/px/PlansPage';
import { Page } from 'src/components';
import { SessionContext } from 'src/context/SessionContext';

const ExplorerPlansView = ({}) => {
  const { signupUrl } = useContext(SessionContext);

  return (
    <Page
      hideBanner
      context="Explorer"
      progress={0.85}
      metadata={{
        title: 'Explore Health Plans',
        description: 'Get covered for 2023',
        image: '/img/og/explorer.png',
      }}
    >
      <PlansPage flow="Explorer" onNext={() => window.open(signupUrl)} />
    </Page>
  );
};

export default ExplorerPlansView;
